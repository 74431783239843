<template>
  <div class="container">
    <ul class="wrapper">
      <!-- 第一部分 -->
      <li class="list-mode">
        <div
          class="no-label"
          v-if="false"
        ></div>
        <div class="number-labels">
          <span>1</span>
        </div>
        <div class="box">
          <h2 class="title-h2">上传表格<span class="mark-title">仅支持Excel表格文件上传，且一次仅能上传一个文件</span></h2>
          <div
            class="select-box"
            v-if="$route.name !== 'baseData'"
          >
            <span class="select-label">数据来源：</span>
            <el-select
              size="mini"
              v-model="dataSource"
              placeholder="请选择"
              @change="changeDataSource"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div>
            <el-upload
              ref="upload"
              :action="uploadUrl"
              :show-file-list="false"
              :headers="uploadHeaders"
              :on-remove="handleRemove"
              :on-success="uploadSuccess"
              :on-progress="uploadProgress"
              :before-remove="beforeRemove"
              :on-exceed="handleExceed"
              :file-list="fileList"
              :accept="accepts"
              :data="{'type': dataSource, 'sourceSystem': dataSource}"
            >
              <p class="upload-btns">
                <i></i>
                上传表格文件
              </p>
            </el-upload>
            <ul class="file-list-box">
              <li v-for="(item, index) in fileList" :key="index">
                <div>
                  <i class="icon-regular"></i>
                  <span>{{ item.name }}</span>
                  <el-progress v-if="isShowProgress" :color="customColor" :stroke-width="4" :percentage="UploadPercent"></el-progress>
                </div>
                <span v-if="uploadStatus === 'uploadSuccess'">上传完成,请看<b> 2 </b>表格数据解析</span>
                <span v-else>{{ uploadProgressText }}</span>
                <i class="icon-close" @click="deleteUploadFile(item)"></i>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <!-- 第二部分 -->
      <li :class="['list-mode', !showDetil ? 'nothing' : '']">
        <div
          class="no-label"
          v-if="!showDetil"
        >
          <span></span>
        </div>
        <div
          class="number-labels"
          v-else
        >
          <span>2</span>
        </div>
        <div class="box">
          <h2 class="title-h2">表格数据解析<span
              v-show="!showDetil"
              class="mark-title"
            >仅支持Excel表格文件上传，且一次仅能上传一个文件</span></h2>
          <template v-if="showDetil">
            <h3 class="title-h3">表格文件中<span class="front-yellow">{{ tableData.successNum }}</span>条数据读取成功，<span class="front-red">{{ tableData.failedNum }}</span>条数据读取失败！失败原因如下：</h3>
            <ul class="err-box">
              <li
                v-for="(item, index) in tableData.failedInfo"
                :key="index"
              >{{ index+1 }}. 表格【第{{ item.line }}行，{{ item.info }}】读取失败</li>
            </ul>
          </template>
        </div>
      </li>
      <!-- 第三部分 -->
      <li class="list-mode">
        <div
          class="no-label"
          v-if="!showDetil"
        >
          <span></span>
        </div>
        <div
          class="number-labels"
          v-else
        >
          <span>3</span>
        </div>
        <div class="box">
          <h2 class="title-h2">操作<span
              v-show="!showDetil"
              class="mark-title"
            >仅支持Excel表格文件上传，且一次仅能上传一个文件</span></h2>
          <h3
            class="title-h3"
            v-show="showDetil"
          >请在下方列表中查看已读取成功的基础数据</h3>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import dataMoveMixin from '@/api/dataMoveMixin'
export default {
  data() {
    return {
      showDetil: false,
      fileList: [],
      dataSource: '',
      uploadUrl: '/cms/inner/dmenterprise/importCompany',
      UploadPercent: 0,
      customColor: '#71C251',
      uploadStatus: 'uploading', // 上传中 uploading 上传完成 uploadSuccess
      isShowProgress: false
    }
  },
  mixins: [dataMoveMixin],
  props: {
    options: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    changeDataSource(val) {
      if (this.$route.name === 'attachFile') {
        if (val === 'BILLS' || val === 'NEW_BILLS') {
          this.uploadUrl = '/cms/inner/dmfile/uploadBillsFile'
        } else {
          this.uploadUrl = '/cms/inner/dmfile/importAttachFileExcel'
        }
        this.$emit('changeDataSource', val)
      }
    },
    uploadSuccessCallback(val) {
      this.tableData = val
      this.showDetil = true
      this.$emit('uploadSuccess')
    },
    chooseUploadUrl() {
      switch (this.$route.name) {
        case 'approveRecord':
          this.uploadUrl = '/cms/inner/dmApprovalRecord/importDMApproval';
          break;
        case 'contractPay':
          this.uploadUrl = '/cms/inner/dmApplyPayment/importDMApply'
          break;
        case 'unionOrder':
        case 'mainContract':
          this.uploadUrl = '/cms/inner/dmcontract/importContract'
          break;
        case 'modifyMove':
          this.uploadUrl = this.URL.importModifyMove
          break;
        case 'agreeMentMove':
          this.uploadUrl = this.URL.importAgreeMentMove
          break;
        case 'attachFile':
          this.uploadUrl = '/cms/inner/dmfile/importAttachFileExcel'
          break;
        default:
          break;
      }
      
    },
    initOption() {
      let dataSource = ''
      switch(this.$route.name) {
        case 'unionOrder':
          dataSource = 'BILLS'
          break
        default:
          dataSource = 'COST'
          break
      }
      this.dataSource = dataSource
    },
    deleteUploadFile(x) {
      this.fileList = this.fileList.filter(item => item.uid !== x.uid)
    }
  },
  mounted() {
    this.chooseUploadUrl()
    this.initOption()
  },
  computed: {
    accepts() {
      var val = this.dataSource
      if (this.$route.name === 'attachFile' && (val === 'BILLS' || val === 'NEW_BILLS')) {
        return '.zip,.rar'
      } else {
        return '.xlsx,.xls'
      }
    },
    uploadProgressText() {
      return this.uploadStatus === 'uploading' ? '正在上传，请稍候...' : '上传失败'
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  // padding-left: 36px;
  padding: 18px;
  box-sizing: border-box;
  overflow: hidden;
  .title-h2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: #323232;
    .mark-title {
      font-size: 12px;
      color: #999999;
      font-weight: 400;
      margin-left: 6px;
    }
  }
  .title-h3 {
    font-weight: 400;
    font-size: 12px;
    color: #323232;
    line-height: 22px;
  }
  .err-box {
    width: 100%;
    padding: 10px 12px;
    box-sizing: border-box;
    border: 1px dashed #d6d6d6;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 50%;
      font-size: 12px;
      line-height: 22px;
      color: #323232;
      font-weight: 400;
      margin-bottom: 2px;
    }
  }
  .wrapper {
    padding-bottom: 10px;
    .list-mode {
      width: 100%;
      height: 100%;
      position: relative;
      padding-bottom: 10px;
      box-sizing: border-box;
      &.nothing {
        padding-bottom: 21px;
      }
      .no-label {
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 100%;
        overflow: hidden;
        span {
          display: block;
          width: 12px;
          height: 12px;
          background: #d6d6d6;
          border-radius: 50%;
          margin: 6px auto;
        }
        &::before {
          content: "";
          position: absolute;
          top: 24px;
          left: 12px;
          width: 0px;
          height: calc(100% - 24px);
          border-left: 2px solid #d6d6d6;
        }
      }
      .number-labels {
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 100%;
        overflow: hidden;
        &::before {
          content: "";
          position: absolute;
          top: 24px;
          left: 11px;
          width: 0px;
          height: calc(100% - 24px);
          border-left: 2px solid #efd193;
        }
        span {
          display: block;
          width: 100%;
          height: 24px;
          line-height: 24px;
          background: #f0d8a8;
          border-radius: 50%;
          text-align: center;
        }
      }
      &:last-child {
        .number-labels,
        .no-label {
          &::before {
            content: "";
            display: none;
          }
        }
      }
      .box {
        padding-left: 36px;
        box-sizing: border-box;
        .select-label {
          font-size: 12px;
          color: #323232;
        }
        .upload-btns {
          width: 120px;
          height: 30px;
          line-height: 30px;
          margin-top: 12px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #efd193;
          box-sizing: border-box;
          border-radius: 2px;
          i {
            display: inline-block;
            width: 12px;
            height: 12px;
            background: url(../../assets/img/upload.png) no-repeat center center;
            background-size: 100%;
            vertical-align: middle;
            margin-top: -2px;
          }
        }
        .select-box {
          margin-top: 12px;
        }
      }
    }
  }
  .file-list-box{
    margin-top: 12px;
    li{
      font-size: 12px;
      color: #323232;
      font-weight: 400;
      border: 1px solid #d6d6d6;
      width: 792px;
      display: flex;
      justify-content: space-between;
      padding: 5px 8px;
      box-sizing: border-box;
      cursor: pointer;
      div{
        width: 526px;
        overflow: hidden;
        i.icon-regular{
          display: inline-block;
          width: 18px;
          height: 18px;
          background: url(../../assets/img/icon-regular.png) no-repeat center center;
          background-size: 100%;
          vertical-align: middle;
          margin-top: -12px;
          margin-right: 6px;
        }
        span{
          color: #323232;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: calc(100% - 33px);
        }
      }
      span{
        font-size: 12px;
        color: #999999;
        b{
          font-weight: 600;
          color: #997236;
        }
      }
      i.icon-close{
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url(../../assets/img/icon-close.png) no-repeat center center;
        background-size: 100%;
      }
    }
  }
}
::v-deep .el-progress__text{
  color: #999999;
  font-size: 10px;
}
::v-deep .el-select {
  width: 120px;
  height: 30px;
  line-height: 30px;
}
::v-deep .el-upload-list__item {
  width: 792px;
  border: 1px solid #d6d6d6;
  border-radius: 0;
  color: #323232;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
}
</style>