export default {
    data() {
        return {
            // 分页
            currentPage: 1,
            total: 0,
            pageSize: 10,
            currentPage2: 1,
            total2: 0,
            pageSize2: 10,
            tableData: {},
        }
    },
    computed: {
        uploadHeaders() {
            return {
                token: this.$store.state.CASTGC || ''
            }
        }
    },
    methods: {
        uploadProgress(res, file, fileList) {
            this.isShowProgress = true
            this.uploadStatus = 'uploading'
            this.fileList = fileList
            this.UploadPercent = Math.floor(res.percent)
        },
        // 上传成功
        uploadSuccess(res, file, fileList) {
            this.$refs.upload.clearFiles()
            if (res.code === '200') {
                this.uploadStatus = 'uploadSuccess'
                var obj = {
                    successNum: res.data.success || 0,
                    failedNum: res.data.failed || 0,
                    failedInfo: res.data.failedInfo
                }
                if (typeof this.uploadSuccessCallback == 'function') {
                    this.uploadSuccessCallback(obj)
                }
            } else {
                // 上传失败
                this.uploadStatus = 'uploadError'
                this.$message.error(res.message)
                this.fileList = this.fileList.filter(item => item.uid != file.uid)
            }
            this.isShowProgress = false
        },
        handleRemove(file, fileList) {
            // this.fileList = []
            console.log(file, fileList)
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${ file.name }？`);
        }
    }
}