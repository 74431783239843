<template>
  <div class="attach-container">
    <div class="container">
      <div class="input-wrapper">
        <input
          class="input"
          type="text"
          v-model="inputVal"
          :placeholder="placeHolder"
          @keyup.enter="searchInput"
        >
        <i></i>
        <p
          class="search-btn"
          @click="searchInput"
        >查询</p>
      </div>
      <div class="btn-wrapper">
        <p
          v-show="$route.name === 'approveRecord' || $route.name === 'attachFile' || $route.name === 'contractPay'"
          :class="['sync-btn', 'ref-version']"
          @click="removeFile"
        >删除全部</p>
        <p
          v-show="$route.name === 'approveRecord' || $route.name === 'attachFile' || $route.name === 'contractPay'"
          :class="['sync-btn', 'ref-version']"
          @click="cancelFileSync"
        >全部取消同步</p>
        <p
          v-show="$route.name === 'mainContract'"
          :class="['sync-btn', 'ref-version']"
          @click="refreshVersion"
        >刷新版本</p>
        <p
          v-show="$route.name !== 'baseData'"
          :class="['sync-btn', isDisabled ? 'no-click' : '']"
          @click="asyncForm"
        >同步业务表</p>
      </div>
    </div>
    <el-dialog
      class="dialog"
      title="提示"
      :visible.sync="dialogVisible"
    >
      <h2 class="hint-text"><i></i>是否将所有未同步数据同步至业务表中？</h2>
      <p class="hint-btns">
        <span @click="dialogVisible = false">取消</span>
        <span @click="asyncClick">同步</span>
      </p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputVal: '',
      dialogVisible: false,
      loading: ''
    }
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: () => false
    },
    placeHolder: {
      type: String,
      default: '请输入主体名称关键词进行搜索'
    },
    asyncUrl: {
      type: String,
      default: '/dmcontract/syncAllData',
    }
  },
  methods: {
    removeFile() {
      this.$emit('removeFile')
    },
    cancelFileSync() {
      this.$emit('cancelFileSync')
    },
    refreshVersion() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$request.get(this.URL.refreshVersion).then(res => {
        loading.close()
        if (res.code === '200') {
          this.$message.success(res.data)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    searchInput() {
      this.$emit('searchInput', this.inputVal)
    },
    asyncForm() {
      this.dialogVisible = true
      console.log(this.$parent.init)
    },
    asyncClick() {
      this.dialogVisible = false
      this.loading = this.$loading({
        lock: true,
        text: '数据同步中，请稍后…',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      this.syncAllData()
    },
    syncAllData() {
      this.$request.get(this.URL.syncAllData).then(res => {
        console.log('同步数据结果', res)
        if (res.code === '200') {
          this.loading.close()
          this.$message.success('数据同步成功')
          this.$parent.init()
        } else {
          this.loading.close()
          this.$message.error('数据同步失败');
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #eaeaea;
  padding: 0 18px;
  padding-top: 18px;
  padding-bottom: 12px;
  box-sizing: border-box;
  div.input-wrapper {
    position: relative;
    .input {
      width: 438px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      padding-left: 33px;
      box-sizing: border-box;
      border: 1px solid #dcdcdc;
      border-radius: 2px;
      outline: none;
    }
    i {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      background: url(../../assets/img/icon-search.png) no-repeat center center;
      background-size: 100%;
    }
    p.search-btn {
      display: inline-block;
      width: 72px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: #f0d8a8;
      font-size: 12px;
      color: #323232;
      margin-left: 12px;
      cursor: pointer;
    }
  }
  div.btn-wrapper{
    display: flex;
  }

  p.sync-btn {
    width: 78px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    background: #f0d8a8;
    color: #323232;
    cursor: pointer;
    &.no-click {
      background: #d6d6d6;
      color: #fff;
    }
  }
  p.ref-version{
    margin-right: 15px;
  }
}
.dialog{
  .hint-text{
    color: #323232;
    font-weight: 400;
    font-size: 14px;
    i{
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 18px;
      background: url(../../assets/img/info.png) no-repeat center center;
      background-size: 100%;
      margin-top: -3px;
      margin-right: 9px;
    }
  }
  .hint-btns{
    width: 100%;
    text-align: right;
    margin-top: 39px;
    span{
      cursor: pointer;
      display: inline-block;
      width: 72px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 12px;
      color: #997236;
      background: #FFFFFF;
      border: 1px solid #F0D8A8;
      box-sizing: border-box;
      border-radius: 2px;
      &:last-child{
        background: #F0D8A8;
        color: #323232;
        margin-left: 12px;
      }
    }
  }
}
::v-deep .el-dialog__header{
  border-bottom: 1px solid #EAEAEA;
}
</style>
<style lang="less">
.asyn-loding {
  .el-loading-spinner {
    top: 50%;
    left: 50%;
    margin-left: -55px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 4px;
    width: auto;
    text-align: center;
    position: absolute;

    i {
      color: #997236;
    }

    .el-loading-text {
      color: #997236;
    }
  }
}
.el-loading-spinner i{
  font-size: 25px;
  color: #997236;
}
.el-loading-spinner .el-loading-text{
  font-size: 12px;
  color: #997236;
}
</style>